import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import colors from '../../../../styles/colors';
import { defaultSectionWidth, largeText, smallSubtitle, smallText } from '../../../../styles/dimensions';
import content from '../../../../content/showcase/stabler/views.json';
import Rigraf from '../../../../fonts/rigraf/regular/rigraf';
import MetroSans from '../../../../fonts/metro-sans/metroSans';

const Views = () => {
	const isSmall = useMediaQuery(useTheme().breakpoints.down(1232));

	const classes = useClasses();

	return (
		<section className={classes.section}>
			<div className={classes.container} data-scroll data-scroll-speed="2">
				<h3 className={classes.label} >
					{content.label}
				</h3>

				<Grid
					container
					spacing={isSmall ? 2 : 6}
					classes={{ root: classes.imageGrid }}>
					<Grid item xs={12} sm={4}>
						<StaticImage
							src="../../../../images/showcase/stabler/home.png"
							alt="Home screen"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							objectFit="contain"
							className={classes.image}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<StaticImage
							src="../../../../images/showcase/stabler/location-selected_location.png"
							alt="Location search screen - selected location"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							objectFit="contain"
							className={classes.image}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<StaticImage
							src="../../../../images/showcase/stabler/nearest.png"
							alt="Nearest tools preview"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							objectFit="contain"
						/>
					</Grid>
				</Grid>
			</div>

			<div data-scroll data-scroll-speed="1">
				<StaticImage
					src="../../../../images/showcase/stabler/stabler_mobile.jpg"
					alt="Stabler showcase"
					layout="fullWidth"
					loading="lazy"
					placeholder="blurred"
					objectFit="cover"
					objectPosition="50% 50%"
					className={classes.showcasePhoneImage}
				/>
			</div>

			<div className={classes.container} data-scroll data-scroll-speed="2">
				<Grid container>
					<Grid item xs={12} sm={5} classes={{ item: classes.problemGridItem }}>
						<h5 className={classes.problemGoalTitle}>{content.problem.title}</h5>
						<p className={classes.problemGoalDescription}>{content.problem.description}</p>
					</Grid>

					<Grid item sm={2}></Grid>

					<Grid item xs={12} sm={5}>
						<h5 className={classes.problemGoalTitle}>{content.goal.title}</h5>
						<p className={classes.problemGoalDescription}>{content.goal.description}</p>
					</Grid>
				</Grid>
			</div>

			<div className={classes.toolsContainer} data-scroll data-scroll-speed="2">
				<div className={classes.yellowDividerTop}></div>
				<div className={classes.container}>
					<Grid container classes={{ root: classes.toolsGrid }}>
						<Grid item xs={12} sm={7}>
							<StaticImage
								src="../../../../images/showcase/stabler/assigned_to_user.png"
								alt="Tool assigned to a user preview"
								layout="constrained"
								loading="lazy"
								placeholder="blurred"
								objectFit="contain"
								className={classes.imageAssigned}
							/>
						</Grid>
						<Grid item xs={12} sm={5} classes={{ item: classes.listGridItem }}>
							<ul>
								{content.features.map((item, key) => (
									<li key={key} className={classes.listItem}>
										{item}
									</li>
								))}
							</ul>
						</Grid>
					</Grid>
				</div>
				<div className={classes.yellowDividerBottom}></div>
			</div>

			<StaticImage
				src="../../../../images/showcase/stabler/showcase_stabler.jpg"
				alt="Stabler showcase"
				layout="fullWidth"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
				objectPosition="50% 55%"
				className={classes.showcaseImage}
			/>
		</section>
	);
};

export default Views;

const useClasses = makeStyles({
	section: {
		backgroundColor: colors.backgroundDarkGray,
		'@media (max-width: 599px)': {
			paddingTop: '4em',
		},
	},
	container: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		'@media(max-width: 1231px)': {
			padding: '0 1em',
		},
	},
	label: {
		color: colors.white,
	},
	imageGrid: {
		'@media (max-width: 599px)': {
			marginBottom: '4em',
		},
	},
	image: {
		'@media (max-width: 599px)': {
			marginBottom: '2em',
		},
	},
	problemGoalTitle: {
		letterSpacing: '0.075em',
		color: colors.white,
		fontFamily: Rigraf.regular,
		marginTop: 0,
		marginBottom: '1.5em',
		'@media (max-width: 599px)': {
			marginBottom: '0.8em',
		},
	},
	problemGoalDescription: {
		...smallText,
		color: colors.careersJobGray,
		margin: 0,
		fontFamily: MetroSans.book,
	},
	problemGridItem: {
		'@media (max-width: 599px)': {
			marginBottom: '2.5em',
		},
	},
	showcasePhoneImage: {
		maxHeight: '100vh',
		marginTop: '2.25em',
		marginBottom: '2.25em',
		'@media (max-width: 599px)': {
			marginTop: '4em',
		},
	},
	toolsContainer: {
		'@media (min-width: 600px)': {
			marginTop: '-10vh',
		},
	},
	toolsGrid: {
		position: 'relative',
		zIndex: 1,
	},
	listGridItem: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	yellowDividerTop: {
		height: '2em',
		backgroundColor: colors.stablerYellow,
		position: 'relative',
		top: '10em',
		zIndex: 0,
		'@media (max-width: 62.5em)': {
			top: '7.5em',
		},
		'@media (max-width: 55em)': {
			top: '5em',
		},
		'@media (max-width: 40.625em)': {
			top: '4em',
		},
		'@media (max-width: 599px)': {
			top: '6em',
		},
	},
	yellowDividerBottom: {
		height: '2em',
		backgroundColor: colors.stablerYellow,
		position: 'relative',
		top: '-10em',
		zIndex: 0,
		'@media (max-width: 62.5em)': {
			top: '-7.5em',
		},
		'@media (max-width: 55em)': {
			top: '-5em',
		},
		'@media (max-width: 40.625em)': {
			top: '-4em',
		},
		'@media (max-width: 599px)': {
			top: '-20em',
		},
	},
	imageAssigned: {
		marginRight: '2em',
	},
	listItem: {
		color: colors.dropdownGray,
		fontFamily: Rigraf.light,
		...smallSubtitle,
		whiteSpace: 'nowrap',
		'@media (max-width: 739px)': {
			...largeText,
		},
	},
	showcaseImage: {
		maxHeight: '100vh',
		marginTop: '0.25em',
		'@media (max-width: 599px)': {
			marginTop: '2em',
		},
	},
});
