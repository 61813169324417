import { makeStyles } from '@material-ui/core';
import React from 'react';
import Layout from '../../components/layout';
import IntroLayout from '../../components/sections/showcase/introLayout';
import Views from '../../components/sections/showcase/stabler/views';
import SEO from '../../components/seo';
import { descriptions, titles } from '../../consts/seo';
import content from '../../content/showcase/stabler/intro.json';
import colors from '../../styles/colors';
// @ts-ignore
import video from '../../videos/stabler.mp4';

const Stabler = () => (
	<Layout rootClassName={useClasses({}).root}>
		<SEO title={titles.showcase.stabler} description={descriptions.showcase.stabler} />

		<IntroLayout content={content} devices={{ mobile: true }} video={video} scaleMobile />
		<Views />
	</Layout>
);

export default Stabler;

const useClasses = makeStyles({
	root: {
		backgroundColor: colors.backgroundDarkGray,
	},
});
